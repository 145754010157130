(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = function(){
    $(window).on('load', function(){
        $(".slider").slick({
             dots: false,
             infinite: true,
             speed: 500,
             fade: true,
             cssEase: 'linear',
             nextArrow: '<button type="button" class="slider-next">Sonraki</button>',
             prevArrow: '<button type="button" class="slider-prev">Önceki</button>',
             appendArrows: $('.slider-buttons'),
             autoplay: true,
             autoplaySpeed: 4000,
             responsive: [
                 {
                     breakpoint: 480,
                     settings: {
                         slidesToShow: 1,
                         slidesToScroll: 1
                     }
                 }
             ]
         });  
    });
}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/slick.js","/modules")