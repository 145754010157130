(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = function(element, call, exit){
	var eScroll = $(element).offset().top,
			h = $(window).height();


	var wScroll = $(window).scrollTop();
	if(wScroll > (eScroll - ((h / 10) * 7.5)) && wScroll < (eScroll + $(element).height() + 10)){
		call(element);
	}else{
		exit(element);
	}

	$(window).bind('scroll', function(){
			wScroll = $(this).scrollTop();

			if(wScroll > (eScroll - ((h / 10) * 7.5)) && wScroll < (eScroll + $(element).height() + 10)){
				call(element);
			}else{
				exit(element);
			}
	});
}
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/scrollDetect.js","/modules")