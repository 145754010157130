(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var slick = require('./modules/slick')();
var scrollDetect = require('./modules/scrollDetect');

$(window).on('load', function(){
	$('body').addClass('lf');
	$('.slider-next').trigger('click');
	
	$('.withUnderline,' + 
		'.btn,' + 
		'.comment,' + 
		'.case,' +
		'.timeline .item,' + 
		'.box').each(function(){
			var th = $(this);
		scrollDetect($(this), function(element){
			if(th.hasClass('case')){
				$(element).find('.iPhone, .iPad').addClass('active');
			}else{
				$(element).addClass('active');
			}
		},function(element){
			if(th.hasClass('case')){
				$(element).find('.iPhone, .iPad').removeClass('active');
			}else{
				$(element).removeClass('active');
			}
		});
	});
	var isMobile = $(window).height() < 768 ? true : false;
	if(!isMobile){
		$('.caseStudies .contentBody').height($(window).height() * $('.caseStudies .contentBody .case').length);
	}
		
});

$(function(){

	$('.mobileMenu').click(function () {
	    $(this).toggleClass('opened');
	    $('.navigation').slideToggle('slow');
	});

	$('.white-section').each(function(index){
		$(this).addClass('white-section-' + (index + 1))
	});

	// scrollDetect('.aboutUs .withUnderline', function(element){
	// 	console.log('enter');
	// },function(element){
	// 	console.log('exit');
	// });
	
});





}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_e01d8c24.js","/")